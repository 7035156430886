import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import "./layout.css"

const Layout = ({ children }) => {
	return (
		<>
			<Helmet>
				<title>AlertPlan</title>
				<link rel="icon" type="image/png" href="/favicon.png"/>
				<link rel="stylesheet" type="text/css" href="https://cloud.typography.com/82384/6556412/css/fonts.css" />
			</Helmet>
			<div id="wrapper">
			<a href="/">
				<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="logo">
					<g id="logo / black">
						<path id="background" d="M47.6476 24.8553L24.8553 47.6476C24.3855 48.1175 23.6266 48.1175 23.1567 47.6476L0.352365 24.8553C-0.117455 24.3855 -0.117455 23.6266 0.352365 23.1567L23.1567 0.352365C23.6266 -0.117455 24.3855 -0.117455 24.8553 0.352365L47.6476 23.1447C48.1175 23.6266 48.1175 24.3855 47.6476 24.8553Z" fill="black"/>
						<g id="icon">
							<path d="M30.8032 20.464L25.1512 13.504C25.1392 13.48 25.1032 13.48 25.0912 13.456C25.0552 13.42 25.0312 13.396 24.9952 13.36C24.9712 13.348 24.9712 13.312 24.9472 13.3C24.9112 13.264 24.8632 13.264 24.8272 13.24C24.7672 13.204 24.7192 13.168 24.6592 13.132C24.6112 13.108 24.5752 13.096 24.5272 13.084C24.4552 13.06 24.3952 13.048 24.3232 13.036C24.2632 13.024 24.2032 13.012 24.1432 13.012C24.1192 13.012 24.0952 13 24.0712 13C24.0472 13 24.0232 13.012 23.9992 13.012C23.9272 13.012 23.8552 13.036 23.7832 13.048C23.7232 13.06 23.6632 13.072 23.6032 13.096C23.5552 13.108 23.5192 13.12 23.4712 13.144C23.4112 13.18 23.3512 13.216 23.2912 13.252C23.2552 13.276 23.2072 13.288 23.1712 13.312C23.1472 13.324 23.1472 13.36 23.1232 13.372C23.0872 13.408 23.0632 13.432 23.0272 13.468C23.0152 13.492 22.9792 13.492 22.9672 13.516L17.3152 20.476C16.8232 21.076 16.9192 21.964 17.5192 22.456C17.7832 22.672 18.0952 22.768 18.4072 22.768C18.8152 22.768 19.2232 22.588 19.4992 22.252L22.6432 18.376V27.028C22.6432 27.808 23.2792 28.432 24.0472 28.432C24.8152 28.432 25.4512 27.796 25.4512 27.028V18.376L28.6072 22.252C28.8832 22.6 29.2912 22.768 29.6992 22.768C30.0112 22.768 30.3232 22.66 30.5872 22.456C31.1992 21.952 31.2952 21.064 30.8032 20.464Z" fill="#FFD900"/>
							<path d="M24.0472 35.848C25.1739 35.848 26.0872 34.9347 26.0872 33.808C26.0872 32.6813 25.1739 31.768 24.0472 31.768C22.9205 31.768 22.0072 32.6813 22.0072 33.808C22.0072 34.9347 22.9205 35.848 24.0472 35.848Z" fill="#FFD900"/>
						</g>
					</g>
				</svg>
				<h6>Alert Plan</h6>
			</a>
				<main>{children}</main>
			</div>
		</>
	)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
